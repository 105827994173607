<template>
  <div>
    <Add v-if="showType != 'view'" v-model:addForm="addForm" :detail-form="detailForm"></Add>
    <View v-else :detailForm="detailForm"></View>
  </div>
</template>

<script>
import Add from "./heat/Add.vue";
import View from "./heat/View.vue";
import _ from "loadsh";
export default {
  data() {
    return {
      addForm: {},
    };
  },
  props:{
    showType:{
      type:String,
      default:''
    },
    submitForm: {
      type: Object,
      default: () => {},
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {},
  watch: {
    addForm: {
      handler(val) {
        // console.log(this.submitForm)
        // console.log(JSON.parse(JSON.stringify(this.submitForm)))
        let submit = _.merge(this.submitForm, {
          comfortDat: val
        })
        if (!val.length) {
          submit = {
            comfortDat: []
          }
        }
        this.$emit("update:submitForm", submit);
      },
      deep: true,
    },
  },
  methods: {},
  components: {
    Add,
    View,
  },
};
</script>

<style scoped lang="scss"></style>
