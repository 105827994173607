<template>
	<div class="heat_feel">
		<label class="fs-18">{{ $t('radar_chart.heat') }}</label>
		<div class="card-header" style="padding: 0.2rem 1rem"></div>
		<b-row style="padding: 20px;">
			<b-col cols='5'>
				<div><img style="width: 100%; height: 400px; object-fit: contain;" src="@/assets/img/thermal_comfort.png" alt="" /></div>
			</b-col>
			<b-col cols="7">
				<div style="position: relative; top:50%; transform: translateY(-50%);">
					<!-- 上传文件 -->
          <UploadFileView
              v-model:selectedList="comfortDatPreview"
              iptId="fileInputHeat"
              accept=".dat"
              :title="$t('attribute.heat_file')"
              hint=""
              tips=""
          ></UploadFileView>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import _ from 'lodash';
import UploadFileView from "@/views/components/UploadFileView-ftt.vue";
export default {
	data() {
		return {
			comfortDat: [],
			comfortDatPreview: [],
      inited: false,
		};
	},
	props: {
		addForm: {
			type: Object,
			default: () => {}
		},
		detailForm: {
			type: Object,
			default: () => {}
		}
	},
	emits: ['update:addForm'],
	watch: {
    comfortDatPreview: {
      handler(val) {
        this.comfortDat = _.map(val, o => o.id);
        this.$emit('update:addForm', [...this.comfortDat]);
      },
      deep: true,
      immediate: true,
    },
    detailForm: {
      handler(val) {
        if (val) {
          if (!this.inited) {
            this.inited = true;
            if (val.comfort_dat) {
              this.comfortDat = [val.comfort_dat];
              this.comfortDatPreview = val.comfort_dat_info
            }
          }
        }
      },
      deep: true,
      immediate: true
    }
	},
	mounted() {},
	methods: {

	},
	components: {UploadFileView}
};
</script>

<style scoped lang="scss">
.heat_img {
	img {
		width: 20rem;
		height: 21rem;
	}
}
</style>
