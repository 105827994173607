<template>
	<div>
    <div v-if="showType != 'view'" class="text-end" style="margin-top: 0.5rem;">
      <button
          type="button"
          class="btn btn-primary me-3"
          @click="openRelatedIndicators"
      >
        <i class="ri-equalizer-fill me-1 align-bottom"></i>
        {{ $t("attribute.indicators_related") }}
      </button>
    </div>
		<Add v-if="showType != 'view'" v-model:addForm="addForm" :detailForm="detailForm" :flag="flag"></Add>
		<View v-else :detailForm="detailForm" :flag="flag"></View>
    <RelatedIndicators ref="relatedIndicators" @save="updateIndexRelation"></RelatedIndicators>
    <message-alert
        :alertInfo="alertInfo"
        v-if="formValidataAlert"
    ></message-alert>
	</div>
</template>

<script>
import Add from './indicators/Add.vue';
import View from './indicators/View.vue';
import RelatedIndicators from "@/views/components/RelatedIndicators";
import _ from 'lodash';
import MsgAlert from "@/mixins/MsgAlert";
export default {
	data() {
		return {
			addForm: {}
		};
	},
  mixins: [MsgAlert],
	props: {
		showType: {
			type: String,
			default: ''
		},
    textileDataType: {
      type: String,
      default: "",
    },
		detailForm: {
			type: Object,
			default: () => {}
		},
		submitForm: {
			type: Object,
			default: () => {}
		},
    flag: {
        type: Number,
        default: 0
    },
    hscode: {
      type: String,
      default: "",
    },
	},
	emits: ['update:submitForm', 'updateIndexRelation'],
	watch: {
		addForm: {
			handler(val) {
				let submit = _.mergeWith(this.submitForm, val, (objV, srcV) => {
					return srcV;
				});
				this.$emit('update:submitForm', submit);
			},
			deep: true
		}
	},
	mounted() {},
	methods: {
    openRelatedIndicators() {
      let index_type = ''
      if (this.flag == 4) {
        index_type = 'business_index';
      } else if (this.flag == 3) {
        index_type = 'quality_index'
      } else {
        index_type = 'envrionmental_index'
      }
      const param = {
        key: this.textileDataType,
        code: this.hscode || this.detailForm['code'],
        index_type: index_type,
        data: this.detailForm[`all_${index_type}`],
      }
      if (!param.code) {
        this.errorAlert(this.$t('attribute.please_set_hscode'));
        return
      }
      this.$refs.relatedIndicators.open(param);
    },
    updateIndexRelation(relations) {
      this.$emit('updateIndexRelation', relations);
    },
  },
	components: {
		Add,
		View,
    RelatedIndicators,
	}
};
</script>

<style scoped lang="scss"></style>
