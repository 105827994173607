<template>
	<div class="hand_feel">
		<label class="fs-18">{{ $t('radar_chart.feel') }}</label>
		<div class="card-header" style="padding: 0.2rem 1rem"></div>
		<b-row>
			<b-col>
				<div class="hand_img"><img style="width: 100%; height: 400px; object-fit: contain;" src="@/assets/img/hand_feel.png" alt="" /></div>
			</b-col>
			<b-col v-if="isAdmin()">
				<div class="hand_info d-flex flex-column justify-content-between" style="position: relative; top:50%; transform: translateY(-50%);">
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.roughness') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.softness') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.warmth') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.total') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
				</div>
			</b-col>
			<b-col>
				<!-- 图表 -->
				<div class="hand_chart ms-5"><div :id="handChartId" style="height: 400px; width: 450px"></div></div>
			</b-col>
		</b-row>
	</div>
	<div class="skin_feel">
		<label class="fs-18">{{ $t('radar_chart.skin') }}</label>
		<div class="card-header" style="padding: 0.2rem 1rem"></div>
		<b-row>
			<b-col>
				<div class="skin_img"><img style="width: 100%; height: 400px; object-fit: contain;" src="@/assets/img/skin_comfort.png" alt="" /></div>
			</b-col>
			<b-col v-if="isAdmin()">
				<div class="skin_info d-flex flex-column justify-content-between" style="position: relative; top:50%; transform: translateY(-50%);">
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.prickle') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.softness') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.warmth') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
					<div class="py-1">
						<label class="form-label">{{ $t('radar_chart.total') }}</label>
						<div class="d-flex align-items-center">
							<input type="text" class="form-control" disabled />
							<div class="text-warning fs-20 ms-5">
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
								<i class="ri-star-line"></i>
							</div>
						</div>
					</div>
				</div>
			</b-col>
			<b-col>
				<div class="skin_chart ms-5"><div :id="skinChartId" style="height: 400px; width: 450px"></div></div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { radarChart } from '@/utils/chart.js';
import { starUI } from '@/utils/common';
import { isAdmin } from '@/utils/permission.js';
export default {
	data() {
		return {
			handChartId: `hand${+new Date()}`,
			skinChartId: `skin${+new Date()}`
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.initChart();
		});
	},
	methods: {
		starUI,
		isAdmin,
		initChart() {
			this.hand_feel_chart();
			this.skin_feel_chart();
		},
		hand_feel_chart() {
			let options = {
				data: [0, 0, 0, 0],
				color: '#50C3E6',
				name: this.$t('radar_chart.feel'),
				chartDom: document.querySelector(`#${this.handChartId}`)
			};
			radarChart(options);
		},
		skin_feel_chart() {
			let options = {
				data: [0, 0, 0, 0],
				color: '#13C56B',
				name: this.$t('radar_chart.skin'),
				chartDom: document.querySelector(`#${this.skinChartId}`)
			};
			radarChart(options);
		}
	},
	components: {}
};
</script>

<style scoped lang="scss">
.hand_img,
.skin_img {
	img {
		width: 20rem;
		height: 22rem;
	}
}
</style>
