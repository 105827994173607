<template>
  <Layout>
    <PageHeader :title="PageHeaderT" :items="PageHeaderItems" />
    <!-- 基本信息 -->
    <DetailTopBaseCard
      v-if="showType == 'view' && detailForm"
      :detail="detailForm"
      :editType="dataType"
      :qrcode-url="qrcodeUrl"
    ></DetailTopBaseCard>
    <div
      :class="[
        'd-flex flex-column card px-3 py-1 overflow-auto',
        showType != 'view' && 'block_content',
      ]"
    >
      <!-- 按钮 -->
      <div v-if="showType != 'view'" class="py-3 pe-3 border-bottom d-flex justify-content-between align-items-center">
        <div class="flex-1 me-4 fw-bold">
          <!--          todo: hscode hscode title-->
          {{ hsCodeTitle }}
        </div>
        <div class="text-end">
          <b-button
              variant="light"
              class="waves-effect waves-light me-4"
              @click="handlerCancel()"
          >{{ $t("base.cancel") }}</b-button
          >
          <b-button
              variant="info"
              class="waves-effect waves-light"
              @click="handlerSubmit()"
          >{{ $t("base.done") }}</b-button
          >
        </div>
      </div>
      <!-- tab切换栏 -->
      <div class="position-relative">
        <div class="table_tab">
          <ul
            class="nav nav-tabs nav-tabs-custom nav-success py-0"
            role="tablist"
          >
            <li class="nav-item" v-for="(tab, i) in tabInfo" :key="i">
              <a
                :class="[
                  'nav-link',
                  'All',
                  'py-3',
                  'position-relative cursor',
                  i == current_tab && 'active',
                ]"
                @click="handlerToggleTab(i)"
                >{{ tab.title }}</a
              >
            </li>
          </ul>
        </div>
        <b-form-checkbox-group
          v-if="showType != 'view'"
          v-model="is_public"
          :options="[$t('base.is_public')]"
          style="
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            padding-right: 10px;
          "
        ></b-form-checkbox-group>
      </div>
      <!-- 切换内容 -->
      <!-- 表单 -->
      <div
        :class="['tab-pane', index == current_tab && 'active']"
        v-for="(info, index) in tabInfo"
        :key="index"
      >
        <component
          :class="[
            (index != 2 && index != 3 && showType != 'view' && 'mt-4') ||
              (index == 0 && showType == 'view' && 'mt-4'),
          ]"
          v-show="index == current_tab"
          :is="info.component"
          :showType="showType"
          :textileDataType="dataType"
          :detailForm="detailForm"
          :searchList="searchList"
          v-model:submitForm="submitForm"
          v-model:hscode="hsCode"
          v-model:hscodeTitle="hsCodeTitle"
          v-model:associatedIndex="associatedIndex"
          @updateIndexRelation="updateIndexRelation"
          :flag="index"
        ></component>
      </div>
    </div>
    <div class="card" v-if="showType == 'view'">
      <div class="card-header">{{ $t("attribute.composition") }}</div>
      <div class="card-body">
        <BaseInfo
          :showType="showType"
          :detailForm="detailForm"
          v-model:submitForm="submitForm"
        ></BaseInfo>
      </div>
    </div>
    <b-card v-show="showSankeyChart">
      <SankeyChartShow :sankey="sankey"></SankeyChartShow>
    </b-card>
    <message-alert
      :alertInfo="alertInfo"
      v-if="formValidataAlert"
    ></message-alert>
  </Layout>
</template>

<script>
import BaseInfo from "./components/BaseInfo.vue";
import Version from "./components/Version.vue";
import Comfortable from "./components/Comfortable.vue";
import Indicators from "./components/Indicators.vue";
// import Quality from "./components/Quality.vue";
import _ from "lodash";
import DetailTopBaseCard from "@/views/components/DetailTopBaseCard.vue";
import {
  getTextileBedwareList,
  getTextileKitchenList,
  getTextileIndustrialList,
  getTextileHomeDecorList,
  getTextileBedwareDetail,
  getTextileHomeDecorDetail,
  getTextileIndustrialDetail,
  getTextileKitchenDetail,
  putTextileBedwareDetail,
  putTextileHomeDecorDetail,
  putTextileIndustrialDetail,
  putTextileKitchenDetail,
  postTextileBedwareDetail,
  postTextileHomeDecorDetail,
  postTextileIndustrialDetail,
  postTextileKitchenDetail,
  getDbIndex,
  getSankeyChartInfo,
} from "@/service";

export default {
  components: {
    DetailTopBaseCard,
    BaseInfo,
  },
  data() {
    return {
      PageHeaderT: this.$t(`${this.$route.query.data_type}.detail.title`),
      PageHeaderItems: [
        {
          text: this.$t(`${this.$route.query.data_type}.parent`),
          active: true,
          href: `/${this.$route.query.data_type}`,
        },
        {
          text: this.$t(`${this.$route.query.data_type}.detail.title`),
          active: true,
        },
      ],
      showType: this.$route.query.type || "",
      dataType: this.$route.query.data_type || "",
      /* tab 信息 */
      tabInfo: [],
      addInfo: [
        {
          title: this.$t("attribute.base"),
          type: "base",
          component: BaseInfo,
          form: [],
        },
        {
          title: this.$t("textile.version"),
          type: "version",
          component: Version,
          form: [],
        },
        {
          title: this.$t("attribute.comfortable"),
          type: "comfortable",
          component: Comfortable,
          form: [],
        },
        {
          title: this.$t("attribute.quality"),
          type: "indicators",
          component: Indicators,
        },
        {
          title: this.$t("attribute.business"),
          type: "indicators",
          component: Indicators,
        },
        {
          title: this.$t("attribute.environment"),
          type: "indicators",
          component: Indicators,
        },
      ],
      viewInfo: [
        {
          title: this.$t("textile.version"),
          type: "version",
          component: Version,
          form: [],
        },
        {
          title: this.$t("attribute.comfortable"),
          type: "comfortable",
          component: Comfortable,
          form: [],
        },
        {
          title: this.$t("attribute.quality"),
          type: "indicators",
          component: Indicators,
        },
        {
          title: this.$t("attribute.business"),
          type: "indicators",
          component: Indicators,
        },
        {
          title: this.$t("attribute.environment"),
          type: "indicators",
          component: Indicators,
        },
      ],
      detailForm: null,
      searchList: null,
      current_tab: this.$route.query.current_tab || 0,
      /* 提交数据表单 */
      submitForm: {},
      /* 分页 */
      tab_info_length: 0,
      page: 1,
      perPage: 10,
      prePageOption: [10, 20, 30, 40, 50],
      pages: [],
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      is_public: [],
      sankey: {},
      // 6位hscode
      hsCode: '',
      hsCodeTitle: '',
      // 关联的指标
      associatedIndex: {},
      detailInfo: null,
      // getTextileDetail: null,
      // putTextileDetail: null,
      // postTextileDetail: null,
      // getTextileList: null,
    };
  },
  watch: {
    showType: {
      handler(val) {
        switch (val) {
          case "edit":
            this.PageHeaderT = this.$t(`${this.$route.query.data_type}.edit`);
            this.initDetail();
            this.tabInfo = this.addInfo;
            break;
          case "view":
            this.PageHeaderT = this.$t(`${this.$route.query.data_type}.detail.title`);
            this.initDetail();
            this.tabInfo = this.viewInfo;
            break;
          case "add":
            this.PageHeaderT = this.$t(`${this.$route.query.data_type}.add`);
            this.initNewInfo();
            this.tabInfo = this.addInfo;
            this.requestDBIndex();
            break;
        }
      },
      immediate: true,
    },
    associatedIndex: {
      handler() {
        this.dealAssociatedIndex("quality_index", this.detailForm.all_quality_index);
        this.dealAssociatedIndex("business_index", this.detailForm.all_business_index);
        this.dealAssociatedIndex("envrionmental_index", this.detailForm.all_envrionmental_index);
      },
      deep: true,
    },
  },
  computed: {
    showSankeyChart() {
      if (this.showType == "view" && this.sankey) {
        const nodes = this.sankey.nodes;
        if (nodes && nodes.length > 1) {
          return true;
        }
        return false;
      }
      return false;
    },
    qrcodeUrl() {
      if (this.detailForm && this.detailForm.id) {
        // console.log(`${window.location.origin}/#/public-textile/${this.detailForm.id}/${this.$route.query.data_type}`)
        return `${window.location.origin}/#/public-textile/${this.detailForm.id}/${this.$route.query.data_type}`;
      }
      return "None";
    },
    getTextileList() {
      switch (this.dataType) {
        case "textile_bedware":
          return getTextileBedwareList;
        case "textile_home_decor":
          return getTextileHomeDecorList;
        case "textile_industrial":
          return getTextileIndustrialList;
        case "textile_kitchen":
          return getTextileKitchenList;
      }
      return null;
    },
    getTextileDetail() {
      switch (this.dataType) {
        case "textile_bedware":
          return getTextileBedwareDetail;
        case "textile_home_decor":
          return getTextileHomeDecorDetail;
        case "textile_industrial":
          return getTextileIndustrialDetail;
        case "textile_kitchen":
          return getTextileKitchenDetail;
      }
      return null;
    },
    putTextileDetail() {
      switch (this.dataType) {
        case "textile_bedware":
          return putTextileBedwareDetail;
        case "textile_home_decor":
          return putTextileHomeDecorDetail;
        case "textile_industrial":
          return putTextileIndustrialDetail;
        case "textile_kitchen":
          return putTextileKitchenDetail;
      }
      return null;
    },
    postTextileDetail() {
      switch (this.dataType) {
        case "textile_bedware":
          return postTextileBedwareDetail;
        case "textile_home_decor":
          return postTextileHomeDecorDetail;
        case "textile_industrial":
          return postTextileIndustrialDetail;
        case "textile_kitchen":
          return postTextileKitchenDetail;
      }
      return null;
    },
  },
  mounted() {},
  methods: {
    requestSankeyChart() {
      const params = {
        id: this.detailForm.id,
        key: this.detailForm.key,
      };
      if (params.key === 'textile') {
        params.key = this.dataType;
      }
      getSankeyChartInfo(params)
        .then((res) => {
          if (res && res.payload) {
            this.sankey = res.payload;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initIndicators(field_name, data) {
      if (this.showType == "edit") {
        // 原来的数据
        const oldData = this.detailInfo[field_name];
        _.forEach(data, function (item) {
          item.unit1 = _.cloneDeep(item.unit);
          if (item.unit1.length) {
            item.unit = item.unit1[0];
          } else {
            item.unit = "";
          }
          // 如果原来的数据中有这个指标，就把原来的数据赋值给新的数据
          const oldItem = _.find(oldData, {
            field_name: item.field_name,
          });
          if (oldItem) {
            item.value = oldItem.value;
            item.unit = oldItem.unit;
          } else {
            item.value = "";
          }
        });
        this.detailForm[field_name] = data;
      } else {
        _.forEach(data, function (item) {
          item.value = "";
          item.unit1 = _.cloneDeep(item.unit);
          if (item.unit1.length) {
            item.unit = item.unit1[0];
          } else {
            item.unit = "";
          }
        });
        if (!this.detailForm) {
          this.detailForm = {};
        }
        this.detailForm[field_name] = data;
      }
    },
    requestDBIndex() {
      const params = {
        key: this.$route.query.data_type,
      };
      getDbIndex(params).then((res) => {
        if (res && res.payload) {
          let info = res.payload;
          this.dealAssociatedIndex("quality_index", info.quality_index);
          this.dealAssociatedIndex("business_index", info.business_index);
          this.dealAssociatedIndex("envrionmental_index", info.envrionmental_index);
        }
      });
    },
    dealAssociatedIndex(fieldName, allIndexes) {
      // 所有指标
      const completeIndex = []
      // 过滤后的指标，用于显示，去掉了没有关联的
      const filteredIndex = []
      // 关联的指标
      const associatedIndex = _.get(this.associatedIndex, fieldName, null)
      // console.log(fieldName, '关联的指标：', associatedIndex)
      // console.log('所有指标：')
      // console.log(allIndexes)
      _.forEach(allIndexes, (item) => {
        const cItem = _.cloneDeep(item)
        cItem.show = true
        // 如果有设置关联指标，且当前指标不在关联指标中，则不显示
        if (associatedIndex != null && !associatedIndex.includes(cItem.field_name)) {
          cItem.show = false
        } else {
          filteredIndex.push(item)
        }
        completeIndex.push(cItem)
      })
      if (!this.detailForm) {
        this.detailForm = {};
      }
      // console.log(allIndexes)
      this.detailForm['all_' + fieldName] = completeIndex
      // console.log('关联后：', this.detailForm['all_' + fieldName])
      this.initIndicators(fieldName, filteredIndex)
    },
    updateIndexRelation(relations) {
      this.updateAssociatedIndex(relations.index_type, relations.data)
    },
    // feildName: quality_index, business_index, envrionmental_index
    // all_index: 完整的，包含show的
    updateAssociatedIndex(fieldName, all_index) {
      const associatedIndex = []
      const filteredIndex = []
      const all = []
      _.forEach(all_index, (item) => {
        const cItem = _.cloneDeep(item)
        all.push(cItem)
        if (cItem.show) {
          associatedIndex.push(cItem.field_name)
          delete cItem.show
          filteredIndex.push(cItem)
        }
      })
      this.associatedIndex[fieldName] = associatedIndex
      this.detailForm[fieldName] = filteredIndex
      this.detailForm['all_' + fieldName] = all
    },
    initDetail() {
      this.getTextileDetail(this.$route.query.id)
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.detailForm = res.payload.data;
            this.detailInfo = _.cloneDeep(res.payload.data);
            // this.submitForm = res.payload.data;
            this.searchList = res.payload.search_list;
            if (res.payload.data && res.payload.data.is_public) {
              this.is_public = [this.$t("base.is_public")];
            }
            if (this.showType == "view") {
              this.requestSankeyChart();
            }
            if (this.showType != "view") {
              this.requestDBIndex();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initNewInfo() {
      this.getTextileList()
        .then((res) => {
          if (res && res.payload && res.payload.data) {
            this.searchList = res.payload.search_list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    /* 切换tab展示内容 */
    handlerToggleTab(index) {
      if (this.current_tab == index) {
        return;
      }
      // this.detailForm = _.cloneDeep(this.submitForm);
      this.current_tab = index;
    },
    handlerSubmit() {
      let params = this.submitForm;
      // params["quality_index"] = [
      //   ...params["structure"],
      //   ...params["safety"],
      //   ...params["protection"],
      //   ...params["features"],
      //   ...params["other"],
      // ];
      // delete params["structure"];
      // delete params["safety"];
      // delete params["protection"];
      // delete params["features"];
      // delete params["other"];
      if (_.get(params, "name", "") == "") {
        this.errorAlert(this.$t("base.hint_name"));
        return;
      }

      if (this.is_public.length > 0) {
        params = _.merge(params, { is_public: true });
      } else {
        params = _.merge(params, { is_public: false });
      }

      if (this.showType == "add") {
        this.postTextileDetail(params)
          .then((res) => {
            if (res) {
              let msgInfo = {
                variant: "success",
                content: this.$t("base.add_success"),
                icon: "ri-alert-line",
              };
              this.openAlert(msgInfo, true);
            }
          })
          .catch((err) => {
            console.log(err);
            this.errorAlert(err);
          });
      } else if (this.showType == "edit") {
        this.putTextileDetail(this.$route.query.id, params)
          .then((res) => {
            if (res) {
              let msgInfo = {
                variant: "success",
                content: this.$t("base.edit_success"),
                icon: "ri-alert-line",
              };
              this.openAlert(msgInfo, true);
            }
          })
          .catch((err) => {
            console.log(err);
            this.errorAlert(err);
          });
      }
    },
    handlerCancel() {
      this.$router.push(`/${this.$route.query.data_type}`);
    },
    /* 消息提示框 */
    openAlert(info, close) {
      if (info) {
        this.alertInfo = info;
      }
      this.formValidataAlert = true;
      if (close) {
        setTimeout(() => {
          this.formValidataAlert = false;
          this.handlerCancel();
        }, 2000);
      }
    },
    errorAlert(msg) {
      this.openAlert({
        variant: "danger",
        content: msg,
        icon: "ri-error-warning-line",
      });
      setTimeout(() => {
        this.formValidataAlert = false;
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
.table-responsive {
  min-height: 60vh;
  max-height: 60vh;
}

.mask_model {
  width: 100vw;
  height: 100vh;
  background-color: rgba(47, 79, 79, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
