<template>
  <!-- 基本信息 -->
  <div class="baseinfo">
    <div
      :class="['row text-gray', index != 0 && 'mt-4']"
      v-for="(base, index) in baseInfo"
      :key="index"
    >
      <div class="col-3">{{ base.title }}</div>
      <div class="col">{{ base.value }}</div>
    </div>
  </div>
  <!-- 2D版型 -->
  <div class="mt-5">
    <label>{{ $t("attribute.version_2d") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div class="mt-3">
      <ul class="d-flex">
        <li
          v-for="(img, index) in shapeList2d"
          :key="index"
          style="width: 150px; height: 200px"
          class="me-3"
        >
          <a href="javascript:;" @click="handleImgPreview(img)">
            <img
              :src="img"
              alt=""
              style="object-fit: contain"
              @error="
                (e) => {
                  e.target.src = defaultImage;
                }
              "
            />
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- 虚拟合身度 -->
  <div class="mt-5">
    <label>{{ $t("attribute.fitting_virtual") }}</label>
    <div class="card-header" style="padding: 0.2rem 1rem"></div>
    <div class="mt-3">
      <ul class="d-flex">
        <li
          v-for="(img, index) in shapeList3d"
          :key="index"
          style="width: 150px; height: 200px"
          class="me-3"
        >
          <a href="javascript:;" @click="handleImgPreview(img)">
            <img
              :src="img"
              alt=""
              style="object-fit: contain"
              @error="
                (e) => {
                  e.target.src = defaultImage;
                }
              "
            />
          </a>
        </li>
      </ul>
      <a
        v-if="file_3d_url"
        href="javascript:;"
        @click="handlerJump(file_3d_url)"
        >{{ file_3d_url }}</a
      >
    </div>
  </div>
  <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg>
</template>

<script>
import PreviewImg from "@/components/common/PreviewImg";
import _ from "lodash";
export default {
  data() {
    return {
      baseInfo: [
        {
          title: this.$t("attribute.body_coverage_ratio"),
          key: "body_coverage_ratio",
          value: "",
        },
        {
          title: this.$t("attribute.size"),
          key: "size",
          value: "",
        },
        // {
        //     title: this.$t('attribute.brand'),
        //     key: "brand_id",
        //     value: "",
        // },
        // {
        //     title: this.$t('attribute.manufacturer'),
        //     key: "manufacturer_id",
        //     value: "",
        // },
      ],
      shapeList2d: [],
      shapeList3d: [],
      showPreviewImg: false,
      src: "",
    };
  },
  props: {
    detailForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    detailForm: {
      handler(val) {
        if (val) {
          let file_2d = _.get(val, "file_2d", []);
          let file_3d = _.get(val, "file_3d", []);
          if (file_2d == "[]") {
            file_2d = [];
          }
          if (file_3d == "[]") {
            file_3d = [];
          }
          this.shapeList2d = file_2d.length ? _.map(file_2d, "url") : [];
          this.shapeList3d = file_3d.length ? _.map(file_3d, "url") : [];
          this.baseInfo.forEach((info) => {
            for (let v in val) {
              if (info.key == v) {
                info.value = val[v];
              }
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    file_3d_url() {
      return _.get(this.detailForm, "file_3d_url", "");
    },
  },
  mounted() {},
  methods: {
    handleImgPreview(item) {
      this.src = item;
      if (this.src == undefined || this.src == null || this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
    handlerJump(url) {
      console.log(url);
      if (url.includes("http://") || url.includes("https://")) {
        window.open(url, "_blank");
      } else {
        window.open("https://" + url, "_blank");
      }
    },
  },
  components: {
    PreviewImg,
  },
};
</script>

<style scoped lang="less"></style>
