<template>
  <div class="comfortable">
    <div class="table_tab mb-4">
      <ul
        class="nav nav-tabs nav-tabs-custom nav-success py-0 border-bottom-0"
        role="tablist"
      >
        <li class="nav-item" v-for="(tab, i) in tabInfo" :key="i">
          <a
            :class="[
              'nav-link',
              'All',
              'py-3',
              'position-relative cursor',
              i == current_tab && 'active',
            ]"
            @click="handlerToggleTab(i)"
            >{{ tab.title }}</a
          >
        </li>
      </ul>
    </div>
    <div
      :class="['tab-pane', index == current_tab && 'active']"
      v-for="(info, index) in tabInfo"
      :key="index"
    >
      <component
        v-show="index == current_tab"
        :is="info.component"
        :showType="showType"
        v-model:submitForm="addForm"
        :detailForm="detailForm"
        :searchList="searchList"
      ></component>
    </div>
  </div>
</template>

<script>
import Heat from "./Heat.vue";
import Feel from "./Feel.vue";
import _ from "lodash";
export default {
  data() {
    return {
      current_tab: this.$route.query.detail_tab || 0,
      addForm: {},
      tabInfo: [],
      addInfo: [
        {
          title: this.$t("attribute.heat_simulation"),
          type: "heat",
          component: Heat,
        },
      ],
      viewInfo: [
        {
          title: this.$t("attribute.feel_skin"),
          type: "feel",
          component: Feel,
        },
        {
          title: this.$t("attribute.heat_simulation"),
          type: "heat",
          component: Heat,
        },
      ],
    };
  },
  props: {
    showType: {
      type: String,
      default: "",
    },
    detailForm: {
      type: Object,
      default: () => {},
    },
    searchList: {
      type: Object,
      default: () => {},
    },
    submitForm: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["update:submitForm"],
  watch: {
    showType: {
      handler(val) {
        if (val == "view") {
          this.tabInfo = this.viewInfo;
        } else {
          this.tabInfo = this.addInfo;
        }
      },
      deep: true,
      immediate: true,
    },
    addForm: {
      handler(val) {
        let submit = _.mergeWith(this.submitForm, val, (objV, srcV) => {
          return srcV;
        });
        this.$emit("update:submitForm", submit);
      },
      deep: true,
    },
  },
  methods: {
    handlerToggleTab(index) {
      if (this.current_tab == index) {
        return;
      }
      this.current_tab = index;
    },
  },
  components: {},
};
</script>

<style scoped lang='less'>
</style>
